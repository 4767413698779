// Styles
import './Menu2.css';

// Icons
import HamburgerIcon from './icons/hamburger.svg';
import CloseMenuIcon from './icons/close-menu.svg';

// Images
import Logo from '../../images/Haads-logos.jpg';

// Core
import { useRef } from 'react';
import { Link } from 'react-router-dom';

const Menu2 = () => {
    const mobileMenuREF = useRef(null);

    const openMobileMenu = () => {
        mobileMenuREF.current.style.zIndex = 1;
        mobileMenuREF.current.style.opacity = 1;
    };

    const closeMobileMenu = () => {
        mobileMenuREF.current.style.zIndex = -1;
        mobileMenuREF.current.style.opacity = 0;
    };

    return (
        <div className='menu2-outer-container'>
            <img
                src={Logo}
                className='menu2-logo'
            />
            <img
                src={HamburgerIcon}
                className='menu2-hamburger-icon'
                onClick={openMobileMenu}
            />
            <div ref={mobileMenuREF} className='menu2-mobile-container'>
                <img
                    src={CloseMenuIcon}
                    alt='Close Menu Icon'
                    className='menu2-close-menu-icon'
                    onClick={closeMobileMenu}
                />
                <ul className='menu2-mobile-links'>
                    <Link to='#' className='menu2-mobile-link'>Build</Link>
                    <Link to='#' className='menu2-mobile-link'>Portfolio</Link>
                    <Link to='#' className='menu2-mobile-link'>Contact</Link>
                </ul>
            </div>
        </div>
    );
};

export default Menu2;
