// Styles
import './CreateWithUs.css';

const CreateWithUs = () => {
    return (
        <h1>CreateWithUs Page</h1>
    );
};

export default CreateWithUs;
