// Styles
import './Footer2.css';

// Icons
import LocationIcon from './icons/location.svg';

// Images
import Logo from '../../images/Haads-logos.jpg';

// Core
import { Link } from 'react-router-dom';

const Footer2 = () => {
    return (
        <div className='footer2-outer-container'>
            <div className='footer2-divider-container-1'></div>
            <img
                src={Logo}
                alt='Haads Software - Logo'
                className='footer2-logo'
            />
            <div className='footer2-location-container'>
                <img
                    src={LocationIcon}
                    alt='Location Icon'
                    className='footer2-location-icon'
                />
                <ul className='footer2-locations'>
                    <li className='footer2-location'>United States</li>
                    {/* <li className='footer2-location'>Los Angeles</li>
                    <li className='footer2-location'>Tešanj</li>
                    <li className='footer2-location'>Dubai</li> */}
                </ul>
            </div>
            <p className='footer2-copyright'>&#169; 2023 Haads Software</p>
            <ul className='footer2-links'>
                <Link to='#' className='footer2-link'>Build</Link>
                <Link to='#' className='footer2-link'>Portfolio</Link>
                <Link to='#' className='footer2-link'>Contact</Link>
            </ul>
        </div>
    )
};

export default Footer2;