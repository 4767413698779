// Styles
import './Home.css';

// Components
import Menu2 from '../../components/Menu2/Menu2';
import Display8 from '../../components/Display8/Display8';
import Display5 from '../../components/Display_5/Display_5';
import Footer2 from '../../components/Footer2/Footer2';

// Core
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    const homePageOuterContainerREF = useRef(null);

    useEffect(() => {
        homePageOuterContainerREF.current.style.opacity = 1;
    }, []);

    return (
        <div ref={homePageOuterContainerREF} className='home-page-outer-container'>
            <Menu2 />
            <div className='home-page-inner-container'>
                <div className='home-page-hero-container'>
                    <h1 className='home-page-hero-title'>Imagination<br />Brought to Life</h1>
                    <p className='home-page-hero-statement'>
                        We focus on creating websites that are not only
                        aesthetically pleasing but also highly functional,
                        with a seamless user experience.
                    </p>
                    <Link to='/create-with-us'className='create-with-us-btn'>Create with us</Link>
                </div>
                {/* <div className='home-page-hero-main-content-divider-container-1'></div> */}
                <Display8 />
                <div className='home-page-content-divider-container-1'></div>
                <Display5 />
                <Footer2 />
            </div>
        </div>
    );
};

export default Home;
