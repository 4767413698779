import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Pages
import Home from './frontend/pages/Home/Home';
import CreateWithUs from './frontend/pages/CreateWithUs/CreateWithUs';

// Core
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/create-with-us',
    element: <CreateWithUs />
  }
  // {
  //   path: '/our-services',
  //   element: <OurServices />
  // },
  // {
  //   path: '/contact-us',
  //   element: <ContactUs />
  // }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
