// Styles
import './Display_5.css';

// Icons
import PlaceholderImage1 from './icons/html-coding.svg';
import PlaceholderImage2 from './icons/programmer.svg';
import PlaceholderImage3 from './icons/curve.svg';
import PlaceholderImage4 from './icons/mosque.svg';
import { useEffect } from 'react';

const Display_5 = () => {
    useEffect(() => console.log(document.getElementsByClassName("display-5-inner-container")[0].offsetWidth), []);

    return (
        <div className='display-5-container'>
            <div className='display-5-inner-container'>
                <p className='display-5-title-context'>DIGITAL VOYAGER</p>
                <p className='display-5-title'>Crafting Digital Experiences Since 2023.</p>
                <div className='display-5-statement-container'>
                    <div className='display-5-statement'>
                        <img
                            className='display-5-statement-icon'
                            src={PlaceholderImage1}
                            alt='Display 5 Statement Icon'
                        />
                        <p className='display-5-statement-title'>Software Experts</p>
                        <p className='display-5-statement-statement'>
                            In hac habitasse platea dictumst. Praesent <span className='display-5-statement-statement-highlight'>congue magna </span> euismod neque maximus, ac consequat 
                            ipsum eleifend. Donec imperdiet suscipit purus eget gravida. Vestibulum tempus nulla sit.
                        </p>
                    </div>
                    <div className='display-5-statement'>
                        <img
                            className='display-5-statement-icon'
                            src={PlaceholderImage3}
                            alt='Display 5 Statement Icon'
                        />
                        <p className='display-5-statement-title'>UI/UX Design</p>
                        <p className='display-5-statement-statement'>
                            In hac habitasse platea dictumst. Praesent congue magna <span className='display-5-statement-statement-highlight'>euismod neque </span> 
                            maximus, ac consequat ipsum eleifend. Donec imperdiet suscipit purus eget gravida. Vestibulum tempus nulla sit.
                        </p>
                    </div>
                    <div className='display-5-statement'>
                        <img
                            className='display-5-statement-icon'
                            src={PlaceholderImage2}
                            alt='Display 5 Statement Icon'
                        />
                        <p className='display-5-statement-title'>Proud Nerds</p>
                        <p className='display-5-statement-statement'>
                            In hac habitasse platea dictumst. Praesent congue magna euismod neque maximus, ac consequat 
                            ipsum eleifend. <span className='display-5-statement-statement-highlight'>Donec imperdiet suscipit </span> purus eget gravida. Vestibulum tempus nulla sit.
                        </p>
                    </div>
                    {/* <div className='display-5-statement'>
                        <img
                            className='display-5-statement-icon'
                            src={PlaceholderImage4}
                            alt='Display 5 Statement Icon'
                        />
                        <p className='display-5-statement-title'>Statement Title</p>
                        <p className='display-5-statement-statement'>
                            In hac habitasse <span className='display-5-statement-statement-highlight'>platea dictumst. </span> 
                            Praesent congue magna euismod neque maximus, ac consequat ipsum eleifend. Donec imperdiet suscipit
                            purus eget gravida. Vestibulum tempus nulla sit.
                        </p>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Display_5;
