// Styles
import './Display8.css';

// Images
import DSSolidConstruction from './images/DS Solid Construction Home Page.png';
import IowaLawnTroopers from './images/Iowa Lawn Troopers Home Page.png';
import AnkgenyGrassGuys from './images/Ankeny Grass Guys Home Page.png';

// Core
import { Link } from 'react-router-dom';

const Display8 = () => {
    return (
        <div className='display8-outer-container'>
            <h2 className='display8-title'>Portfolio</h2>
            <p className='display8-statement'>
                Discover our past creations. We've helped countless people
                and companies establish their digital presence.
            </p>
            <div className='display8-cards-container'>
                <div className='display8-card'>
                    <Link to='https://www.dssolidconstruction.com/'>
                        <img
                            src={DSSolidConstruction}
                            alt='DS Solid Construction Home Page Image - Showcasing Design Abilities'
                            className='display8-card-image'
                        />
                    </Link>
                    <div className='display8-card-title-url-container'>
                        <p className='display8-card-title'>DS Solid Construction</p>
                       <Link to='https://www.dssolidconstruction.com/' className='display8-card-url'>dssolidconstruction.com</Link>
                    </div>
                </div>
                <div className='display8-card'>
                    <Link to='https://www.iowalawntroopers.com/'>
                        <img
                            src={IowaLawnTroopers}
                            alt='Iowa Lawn Troopers Home Page Image - Showcasing Design Abilities'
                            className='display8-card-image'
                        />
                    </Link>
                    <div className='display8-card-title-url-container'>
                        <p className='display8-card-title'>Iowa Lawn Troopers</p>
                       <Link to='https://www.iowalawntroopers.com/' className='display8-card-url'>iowalawntroopers.com</Link>
                    </div>
                </div>
                <div className='display8-card'>
                    <Link to='https://www.ankenygrassguys.com/'>
                        <img
                            src={AnkgenyGrassGuys}
                            alt='Iowa Lawn Troopers Home Page Image - Showcasing Design Abilities'
                            className='display8-card-image'
                        />
                    </Link>
                    <div className='display8-card-title-url-container'>
                        <p className='display8-card-title'>Ankeny Grass Guys</p>
                       <Link to='https://www.ankenygrassguys.com/' className='display8-card-url'>ankenygrassguys.com</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Display8;
